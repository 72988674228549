import _ from '@/utils/lodash'
import { likeRegExp } from '@/offline/database'
import { STOCK } from '@/utils/consts'

export default {
  async _addSubqueriesArticulo (Vue, rows, idttarifa, idbancoPrecio) {
    for (let row of rows) {
      const articulo = await Vue.$offline.articulo.buscarArticulo(
        row.articulo.codigo,
        idttarifa,
        idbancoPrecio
      )
      if (articulo.precio > 0) {
        row.precio = articulo.precioConDto
        row.precio_con_iva = articulo.precioConDtoConIVA
      } else {
        row.precio = null
        row.precio_con_iva = null
      }
    }
    return rows
  },
  _selectArticuloBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        ...Vue.$offline.articulo.columns,
        tables.subfamilia.idsubfamilia,
        tables.familia.idfamilia
      )
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.familia,
        tables.subfamilia.idfamilia.eq(tables.familia.idfamilia)
      )
  },
  selectArticuloMetadata (sorter) {
    const metadata = {
      sorter_desc: [
        { label: 'Código', name: 'codigo', field: 'articulo.codigo' },
        { label: 'Descripción', name: 'descripcion', field: 'articulo.descripcion' },
        { label: 'Código de proveedor', name: 'codpro', field: 'articulo.codpro' },
        { label: 'Familia', name: 'familia_descripcion', field: 'familia.descripcion' },
        { label: 'Subfamilia', name: 'subfamilia_descripcion', field: 'subfamilia.descripcion' },
      ],
    }
    if (!_.isEmpty(sorter)) {
      metadata.sorter = sorter
    } else {
      metadata.sorter = [
        { field: 'descripcion', asc: true },
      ]
    }
    return metadata
  },
  async selectArticulo (Vue, filter, search, sorter, page, idttarifa, idbancoPrecio) {
    const tables = Vue.$offline.db.tables
    let metadata = this.selectArticuloMetadata(sorter)
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      const articulosCodigosBarra = await Vue.$offline.db
        .select(
          Vue.$offline.db.fn.distinct(tables.articulo_codbarras.idarticulo).as('idarticulo')
        )
        .from(tables.articulo_codbarras)
        .where(tables.articulo_codbarras.codbarras.match(reQ))
        .exec()
      const idsArticulosCodigosBarra = _.map(articulosCodigosBarra, 'idarticulo')
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.articulo.codigo.match(reQ),
          tables.articulo.codpro.match(reQ),
          tables.articulo.codbarras.match(reQ),
          tables.articulo.descripcion.match(reQ),
          tables.articulo.idarticulo.in(idsArticulosCodigosBarra),
        )
      )
    }
    if (filter.codigo.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.codigo.match(likeRegExp(filter.codigo.value))
        )
      )
    }
    if (filter.codpro.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.codpro.match(likeRegExp(filter.codpro.value))
        )
      )
    }
    if (filter.descripcion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.descripcion.match(likeRegExp(filter.descripcion.value))
        )
      )
    }
    if (filter.frecuente.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.frecuente.eq(true)
        )
      )
    }
    if (filter.sinStock.value && filter.sinStock.value.length > 0) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.sin_stock.in(filter.sinStock.value)
        )
      )
    }
    if (filter.almacen.value && filter.almacen.value.length > 0) {
      const articulosStock = await Vue.$offline.db
        .select(
          Vue.$offline.db.fn.distinct(tables.stock.idarticulo).as('idarticulo')
        )
        .from(tables.stock)
        .where(tables.stock.idalmacen.in(filter.almacen.value))
        .exec()
      const idsArticulosStock = _.map(articulosStock, 'idarticulo')
      whereFilter.push(
        Vue.$offline.db.op.or(
          tables.articulo.idarticulo.in(idsArticulosStock),
          tables.articulo.sin_stock.eq(STOCK.valor.noControla)
        )
      )
    }
    if (filter.subfamilia.value && filter.subfamilia.value.length > 0) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.idsubfamilia.in(filter.subfamilia.value)
        )
      )
    }
    if (filter.marca.value && filter.marca.value.length > 0) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.idmarca.in(filter.marca.value)
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.articulo.estado.gt(0),
        tables.articulo.visible_movilidad.eq(true),
        ...whereSearch,
        ...whereFilter
      )
    )
    let query = this._selectArticuloBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
    query = Vue.$offline.db.applySorter(query, metadata)
    const rows = await query.exec()
    return [
      await this._addSubqueriesArticulo(Vue, rows, idttarifa, idbancoPrecio),
      metadata
    ]
  },
  async selectArticuloRows (Vue, pks, idttarifa, idbancoPrecio) {
    const tables = Vue.$offline.db.tables
    const query = this._selectArticuloBase(Vue)
      .where(tables.articulo.idarticulo.in(pks))
    const rows = await query.exec()
    return this._addSubqueriesArticulo(Vue, rows, idttarifa, idbancoPrecio)
  },
  async selectSubfamilia (Vue) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.subfamilia.select({
      where: Vue.$offline.db.op.and(
        tables.subfamilia.estado.gt(0)
      )
    })
  },
  async selectMarca (Vue) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.marca.select({
      where: Vue.$offline.db.op.and(
        tables.marca.estado.gt(0)
      )
    })
  },
  async selectArticuloCodigoDeBarra(Vue, idarticulo){
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.articulo_codbarras.select({
      where: Vue.$offline.db.op.and(
        tables.articulo_codbarras.estado.gt(0),
        tables.articulo_codbarras.idarticulo.eq(idarticulo)
      )
    })
  }
}
