<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  color="info"
                  dark
                  label
                >
                  F
                </v-chip>
              </td>
              <td>
                Artículo frecuente
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="warning"
                  dark
                  label
                >
                  Obsoleto
                </v-chip>
              </td>
              <td>
                Artículo obsoleto
              </td>
            </tr>
            <tr
              v-for="(item, index) in STOCK.valor"
              :key="index"
            >
              <td
                v-if="item !== STOCK.valor.controla"
              >
                <v-chip
                  color="warning"
                  dark
                  label
                >
                  {{ STOCK.abreviaturas[item] }}
                </v-chip>
              </td>
              <td
                v-else
              >
                <v-chip
                  color="white"
                  dark
                  label
                >
                  {{ STOCK.abreviaturas[item] }}
                </v-chip>
              </td>
              <td>
                {{ STOCK.descripcionLarga[item] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from "@/mixins/internalShowingMixin";
import { STOCK } from "@/utils/consts";

export default {
  mixins: [internalShowingMixin],
  data() {
    return {
      STOCK,
    };
  },
};
</script>
